import landing from "@/components/sharedComponents/landing/landing.component";
import { LandingItem } from "@/interfaces/landingItem";
import { EventBus } from "@/internal";
import { PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import ChoiceModal from "./choiceModal/ChoiceModal.component";
import Template from "./ImportExportWizard.template.vue";

@Component({
  mixins: [Template],
  components: {
    ChoiceModal,
    landing
  }
})
export default class ImportExportWizardComponent extends Vue {
  public landingOptions: LandingItem[] = [
    {
      namedRoute: "batch-types",
      translationString: "batch_types"
    },
    {
      namedRoute: "product-categories",
      translationString: "product_categories"
    },
    {
      namedRoute: "brands",
      translationString: "brands"
    },
    {
      namedRoute: "strains",
      translationString: "strains"
    },
    {
      namedRoute: "vendors-laboratories",
      translationString: "vendors_labs"
    },
    {
      namedRoute: "products",
      translationString: "products"
    },
    {
      namedRoute: "customers",
      translationString: "customers"
    },
    {
      namedRoute: "lab-results",
      translationString: "lab_results"
    }
  ];

  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public modal: boolean = false;
  public csvType: string = "";

  public back() {
    this.$router.back();
  }

  protected toggleModal(type?: string): void {
    if (type === "lab-results") {
      this.$router.push({
        name: "import-wizard",
        params: { type }
      });
      return;
    }
    if (type && this.csvType !== type) {
      this.modal = true;
      this.csvType = type;
      return;
    }
    this.modal = !this.modal;
  }

  protected mounted() {
    this.setPageNav({ title: "importModule.wizard_title" });
    EventBus.$on("closeModal", this.toggleModal);
  }
}
